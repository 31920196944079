<script>
  import trapFocus from '@/helpers/trapFocus'
  import screen from '@/helpers/screen'

  /*
   * A centered modal dialog with a backdrop, potentially dismissible by the user.
   */
  export default {
    props: {
      /** Whether the modal is currently open (visible). */
      open: {
        type: Boolean,
        default: false,
      },
      /** Whether the modal is dismissible by the user. If `true`, a “X” button appears in the top right corner, and the user can either click on it, click anywhere outside the modal, or press Esc to dismiss it (at which point a `dismiss` event is emitted). */
      dismissible: {
        type: Boolean,
        default: false,
      },
      /** The modal’s size (width). */
      size: {
        type: String,
        default: 'md',
        validator: (value) => ['xs', 'xs-sm', 'sm', 'sm-md', 'md', 'lg'].includes(value),
      },
      /** The modal’s height. */
      height: {
        type: String,
        default: undefined,
        validator: (value) => ['none', 'xs', 'sm', 'md', 'lg'].includes(value),
      },
      /** Use to keep scrolling within modal content section */
      limitScreenHeight: {
        type: Boolean,
        default: false,
      },
      /** Whether the horizontal padding should be removed from the modal. */
      noHorizontalPadding: {
        type: Boolean,
        default: false,
      },
      /** Whether the vertical padding should be removed from the modal. */
      noVerticalPadding: {
        type: Boolean,
        default: false,
      },
      /** Only if `dismissible` is `true`. Whether to hide the default “X” dismiss icon. */
      hideIcon: {
        type: Boolean,
        default: false,
      },
      /** Makes icon always visible while scrolling */
      stickyIcon: {
        type: Boolean,
        default: false,
      },
      /** Whether the content in the modal should be centered vertically. */
      centerContent: {
        type: Boolean,
        default: false,
      },
      /** Background color of the modal. */
      bgColor: {
        type: String,
        default: 'white',
        validator: (value) => ['white', 'dawn', 'dawn-lt1', 'dawn-lt3', 'primary'].includes(value),
      },
      /** Whether `AppHeader` should be hidden on mobile. */
      hideNavOnMobile: {
        type: Boolean,
        default: false,
      },
      /** Turns modal full screen for mobile with a fixed close button at bottom */
      mobileFullScreen: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        previouslyOpen: false,
        previouslyFocusedElement: null,
        boxRef: null,
        boxContainerRef: null,
      }
    },
    computed: {
      screen,
      /** Only activate full screen modal for !this.screen.sm */
      mobileFullScreenActive() {
        return !this.screen.sm && this.mobileFullScreen
      },
      widthClass() {
        switch (this.size) {
          case 'xs':
            return 'sm:w-100'
          case 'xs-sm':
            return 'sm:w-120'
          case 'sm':
            return 'sm:w-140'
          case 'sm-md':
            return 'sm:w-160'
          case 'md':
            return 'sm:w-180'
          case 'lg':
            return 'sm:w-220'
          default:
            return ''
        }
      },
      heightClass() {
        switch (this.height) {
          case 'xs':
            return 'sm:h-100'
          case 'sm':
            return 'sm:h-140'
          case 'md':
            return 'sm:h-180'
          case 'lg':
            return 'sm:h-220'
          case 'max-h-lg':
            return 'sm:max-h-220'
          default:
            return ''
        }
      },
      screenHeightClass() {
        if (this.limitScreenHeight) {
          return 'limit-screen-height'
        } else {
          return ''
        }
      },
      dimensions() {
        if (this.mobileFullScreenActive) {
          return `absolute w-full h-full`
        } else {
          return `${this.widthClass} ${this.heightClass}`
        }
      },
      hasHorizontalPadding() {
        return !this.noHorizontalPadding
      },
      hasVerticalPadding() {
        return !this.noVerticalPadding
      },
      verticalPaddingClass() {
        if (this.mobileFullScreenActive) {
          return `
            ${this.size !== 'xs' ? 'pt-10' : ''}
            ${this.size === 'xs' ? 'pt-6' : ''}
            pb-32
          `
        } else {
          return `
            ${this.hasVerticalPadding && this.size !== 'xs' ? 'py-10' : ''}
            ${this.hasVerticalPadding && this.size === 'xs' ? 'py-6' : ''}
          `
        }
      },
      mobileDismissBtnColor() {
        if (this.bgColor === 'primary') {
          /** Currently no dawn base button color but may come in handy in the future */
          return 'white'
        } else if (this.bgColor === 'dawn') {
          return 'primary'
        } else {
          return 'primary'
        }
      },
      baseIconClasses() {
        const bgColor = this.bgColor === 'primary' ? 'text-dawn' : ''
        const absolutePosition = 'absolute right-0 mr-4 top-0 mt-4 z-20'
        const stickyPosition = 'sticky sticky-icon top-0 -mb-28px ml-auto -mr-24px z-20'

        return `${this.stickyIcon ? stickyPosition : absolutePosition} ${bgColor}`
      },
    },
    watch: {
      open: {
        immediate: true,
        async handler() {
          // If the modal was just opened, focus on it
          if (this.open) {
            this.previouslyOpen = true
            this.previouslyFocusedElement = document.activeElement
            await this.ensureBoxRefExists()
            this.boxRef.focus()
            await this.$wait(0)
            this.boxContainerRef.scrollTop = 0
            this.$store.set('general/increaseOpenModalCount')
          }
          // If the modal was just closed, focus on the element that was focused before it got opened
          if (!this.open && this.previouslyOpen) {
            if (this.previouslyFocusedElement) {
              this.previouslyFocusedElement.focus()
            }
            this.$store.set('general/decreaseOpenModalCount')
          }
        },
      },
    },
    created() {
      window.addEventListener('keydown', this.handleKeyDown)
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyDown)
      if (this.open) {
        this.$store.set('general/decreaseOpenModalCount')
      }
    },
    methods: {
      async ensureBoxRefExists() {
        await this.$waitForValue(() => this.boxRef)
      },
      handleKeyDown($event) {
        // If the escape key was pressed and the modal is open and dismissible, dismiss it
        if ($event.keyCode === 27 && this.open && this.dismissible) {
          this.dismiss()
          return
        }
        // If the modal is open, trap the focus inside it
        if (this.open) {
          trapFocus($event, this.boxRef)
        }
      },
      dismiss() {
        /** Emitted when the modal is dismissed. */
        this.$emit('dismiss')
      },
    },
  }
</script>

<template>
  <BaseTransitionFade>
    <!-- Need to have different z-indexes so the header shows on mobile and gets hidden on desktop -->
    <div
      :class="`${
        hideNavOnMobile ? 'z-80' : 'z-60 md:z-80'
      } fixed inset-0 pointer-events-none duration-400`"
    >
      <transition name="overlay" appear>
        <div v-if="open" class="absolute inset-0 bg-true-black opacity-80" />
      </transition>
      <div :class="`absolute inset-0 overflow-y-auto ${open ? 'pointer-events-auto' : ''}`">
        <div
          v-ref="boxContainerRef"
          :class="`min-h-full flex justify-center items-start sm:items-center sm:px-4 ${
            hideNavOnMobile ? '' : 'pt-16'
          } sm:py-10 overflow-hidden`"
        >
          <div v-if="dismissible && open" class="absolute inset-0" @click="dismiss" />
          <transition name="box" appear>
            <!-- for purgecss: bg-white bg-dawn bg-dawn-lt1 bg-dawn-lt3 bg-primary -->
            <section
              v-if="open"
              v-ref="boxRef"
              tabindex="-1"
              :class="`w-full modal-scrollbar 
                ${dimensions} 
                ${hasHorizontalPadding ? 'px-6' : ''}
                ${hasHorizontalPadding && size !== 'xs' ? 'sm:px-10' : ''}
                ${verticalPaddingClass}
                ${screenHeightClass}
                ${centerContent ? 'flex flex-col justify-center' : ''}
                rounded-sm bg-${bgColor} ${
                bgColor === 'primary' ? 'text-dawn dawn-scrollbar' : 'primary-scrollbar'
              } shadow-xl focus:outline-none overflow-x-hidden`"
              style="overflow: overlay"
            >
              <BaseIconClickable
                v-if="dismissible && !hideIcon && !mobileFullScreenActive"
                label="Close"
                color="black"
                :class="baseIconClasses"
                @click="dismiss"
              >
                <IconX />
              </BaseIconClickable>
              <!-- @slot The modal’s content. Can contain anything. -->
              <slot />
              <!-- for purgecss: bg-dawn bg-primary -->
              <div
                v-if="mobileFullScreenActive"
                :class="`w-full fixed bottom-0 -mx-6 bg-${bgColor}`"
                style="box-shadow: 0 -1px 4px 0 rgba(0, 40, 35, 0.15)"
              >
                <BaseButton
                  class="px-6 pt-15px pb-7"
                  :color="mobileDismissBtnColor"
                  @click="dismiss"
                  >Got it</BaseButton
                >
              </div>
            </section>
          </transition>
        </div>
      </div>
    </div>
  </BaseTransitionFade>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */

  .modal-scrollbar {
    @screen md {
      &::-webkit-scrollbar {
        @apply w-5;
      }

      &::-webkit-scrollbar-track {
        @apply mt-15 mb-4;
      }

      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border: 0.5em solid rgba(0, 0, 0, 0); /* Transparent border together with `background-clip: padding-box` adds padding to the right */
        -webkit-border-radius: 1em;
      }
    }
  }

  .dawn-scrollbar {
    @screen md {
      &::-webkit-scrollbar-thumb {
        @apply bg-dawn-dk2;
      }
    }
  }

  .primary-scrollbar {
    @screen md {
      &::-webkit-scrollbar-thumb {
        @apply bg-gray-opacity68;
      }
    }
  }

  .overlay {
    &-enter-active,
    &-leave-active {
      @apply transition duration-400;
    }

    &-enter,
    &-leave-to {
      @apply opacity-0;
    }
  }

  .box {
    &-enter-active,
    &-leave-active {
      @apply transform transition duration-400;
    }

    &-enter,
    &-leave-to {
      @apply opacity-0 translate-y-full;

      @screen sm {
        @apply translate-y-0 scale-90;
      }
    }
  }

  .sticky-icon {
    transform: translateY(-24px);
  }

  .limit-screen-height {
    max-height: 75vh;
  }
</style>
